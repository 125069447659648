import type { Align, Matrix, StringBoolean } from './types';

export const isBoolean = (value: unknown) => typeof value === 'boolean';
export const isArray = <Entry>(value: unknown): value is Entry[] | never[] => Array.isArray(value);
export const isObject = (value: unknown): value is Record<string, unknown> => typeof value === 'object' && value !== null && !isArray(value);
export const isErrorLoggable = (value: unknown): value is Error|string => typeof value === 'string' || value instanceof Error;

export function assertIsValidAlignValue(value: unknown): asserts value is Align {
    if (typeof value !== 'string' || !['left', 'center', 'right'].includes(value)) {
        throw Error('Text align value is incorrect');
    }
}

export function assertIsValidStringBooleanValue(value: unknown): asserts value is StringBoolean {
    if (typeof value !== 'string' || !['true', 'false'].includes(value)) {
        throw Error('Text boolean value is incorrect');
    }
}

export function assertIsValidNumericStringValue(value: unknown): asserts value is string {
    if (value === null || typeof value === 'boolean' || Number.isNaN(Number(value))) {
        throw Error('Text value is incorrect');
    }
}

export function assertValueIsMatrix(value: unknown): asserts value is Matrix {
    if (Array.isArray(value) === false || (Array.isArray(value) && (value.length !== 6 || !value.every((v: unknown) => typeof v === 'number')))) {
        throw Error('Text matrix is incorrect');
    }
}
