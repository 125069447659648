/* eslint-disable import/no-import-module-exports */
import { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Cookie from 'js-cookie';
import { document, window } from '../../js/globals';
import {
    clearPersistedDiscountData,
    DiscountDataKey,
    getPersistedDiscountData,
} from './Storage/LocalDiscountDataStorage';

if (module.hot) {
    module.hot.accept();
}

const loadDiscountPopup = (dc?: string, action?: string, medium?: string) => {
    const App = lazy(() => import('./Components/App/App'));

    const root = createRoot(document?.getElementById('discount-popup') as Element);
    root.render(<Suspense><App showPopup={!!dc} dc={dc} action={action} medium={medium}/></Suspense>);
};

if (document && window) {
    const queryParams = new URLSearchParams(window.location.search);

    const dc = queryParams.get('dc') ?? queryParams.get('dCampaign') ?? undefined;
    const medium = queryParams.get('utm_medium') ?? undefined;
    const action = queryParams.get('da') ?? undefined;

    /**
     * We want to clear any persisted discount (campaign) data if the discount has been removed from the cart.
     * Otherwise, we'll have a discount campaign banner that remains visible even after the corresponding discount is no longer active.
     *
     * The reason we do this via a cookie is that the server can remove the discount via unrelated endpoints.
     */
    if (Cookie.get(DiscountDataKey.CLEAR_DISCOUNT_DATA_COOKIE_KEY)) {
        clearPersistedDiscountData();
        Cookie.remove(DiscountDataKey.CLEAR_DISCOUNT_DATA_COOKIE_KEY);
    }

    if (dc || getPersistedDiscountData()) {
        loadDiscountPopup(dc, action, medium);
    }
}
