import { localStorage } from '../../../js/util/storage';
import type { DiscountData } from '../Provider/DiscountDataProvider';
import type { Potential } from '../../../js/types';
import { isObject } from '../../../js/typeguards';

enum DiscountDataKey {
    CLEAR_DISCOUNT_DATA_COOKIE_KEY = 'CLEAR_DISCOUNT_CAMPAIGN_DATA',
    LOCAL_STORAGE_KEY = 'discount-data',
}

export type PersistedDiscountData = DiscountData & { updatedAt: string };

const clearPersistedDiscountData = () => {
    try {
        localStorage.removeItem(DiscountDataKey.LOCAL_STORAGE_KEY);
    } catch (error) {
        // Local storage is not writable.
    }
};

const isValidDiscountData = (discountData: Potential<PersistedDiscountData>): discountData is PersistedDiscountData => (
    isObject(discountData)
        && typeof discountData?.dc === 'string'
        && typeof discountData?.updatedAt === 'string'
        && isObject(discountData?.response)
);

const getPersistedDiscountData = (): PersistedDiscountData | null => {
    try {
        const discountData = JSON.parse(localStorage.getItem(DiscountDataKey.LOCAL_STORAGE_KEY) ?? '') as PersistedDiscountData;

        if (isValidDiscountData(discountData)) {
            return discountData;
        }

        clearPersistedDiscountData();

        return null;
    } catch (error) {
        return null;
    }
};

const saveDiscountDataToLocalStorage = (data: DiscountData | null): void => {
    try {
        localStorage.setItem(DiscountDataKey.LOCAL_STORAGE_KEY, JSON.stringify({
            ...data,
            updatedAt: new Date(),
        }));
    } catch (error) {
        // Local storage is not writable.
    }
};

const isPersistedDiscountDataStale = (persistedDiscountData: PersistedDiscountData | null): boolean => {
    if (!persistedDiscountData) {
        return false;
    }

    const datesAreOnSameDay = (first: Date, second: Date) => first.getFullYear() === second.getFullYear()
        && first.getMonth() === second.getMonth()
        && first.getDate() === second.getDate();

    return !datesAreOnSameDay(new Date(persistedDiscountData.updatedAt), new Date());
};

export {
    clearPersistedDiscountData,
    getPersistedDiscountData,
    saveDiscountDataToLocalStorage,
    isPersistedDiscountDataStale,
    DiscountDataKey,
};
