/**
 * ISC License (ISC). Copyright 2017 Michal Zalecki
 * Original: https://michalzalecki.com/why-using-localStorage-directly-is-a-bad-idea/
 */
export default class StorageWrapper implements Storage {
    private inMemoryStorage: { [key: string]: string } = {};

    public constructor(
        private readonly getStorage: () => Storage,
    ) {
    }

    public get length(): number {
        if (this.isSupported()) {
            return this.getStorage().length;
        }
        return Object.keys(this.inMemoryStorage).length;
    }

    public clear(): void {
        if (this.isSupported()) {
            this.getStorage().clear();
        } else {
            this.inMemoryStorage = {};
        }
    }

    public getItem(name: string): string | null {
        if (this.isSupported()) {
            return this.getStorage().getItem(name);
        }
        return this.inMemoryStorage[name] ?? null;
    }

    public key(index: number): string | null {
        if (this.isSupported()) {
            return this.getStorage().key(index);
        }
        return Object.keys(this.inMemoryStorage)[index] || null;
    }

    public removeItem(name: string): void {
        if (this.isSupported()) {
            this.getStorage().removeItem(name);
        } else {
            delete this.inMemoryStorage[name];
        }
    }

    public setItem(name: string, value: string): void {
        if (this.isSupported()) {
            this.getStorage().setItem(name, value);
        } else {
            this.inMemoryStorage[name] = value;
        }
    }

    private isSupported(): boolean {
        try {
            const testKey = '__YSP_storage_test_key__';
            this.getStorage().setItem(testKey, testKey);
            this.getStorage().removeItem(testKey);
            return true;
        } catch (e) {
            return false;
        }
    }
}
