import StorageWrapper from './StorageWrapper';
import { window } from '../../globals';
import MemoryStorage from './MemoryStorage';

const localMemoryStorage = new MemoryStorage();
const sessionMemoryStorage = new MemoryStorage();

/* eslint-disable-next-line no-restricted-properties */
export const localStorage = new StorageWrapper(() => window?.localStorage ?? localMemoryStorage);
/* eslint-disable-next-line no-restricted-properties */
export const sessionStorage = new StorageWrapper(() => window?.sessionStorage ?? sessionMemoryStorage);
