export default class MemoryStorage implements Storage {
    private content: Record<string, string | undefined> = {};

    public get length(): number {
        return Object.keys(this.content).length;
    }

    clear(): void {
        this.content = {};
    }

    getItem(key: string): string | null {
        return this.content[key] ?? null;
    }

    key(index: number): string | null {
        return Object.keys(this.content)[index] ?? null;
    }

    removeItem(key: string): void {
        delete this.content[key];
    }

    setItem(key: string, value: string): void {
        this.content[key] = value;
    }
}
